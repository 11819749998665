import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../Layout'

export const AboutUsTemplate = ({
  secondColumn,
  firstColumn,
  description,
  title,
}) => {
  return (
    <>
      <section className="section">
        <div className="container content">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                {title}
              </h1>
              {description && <p className="body-small">{description}</p>}
              <div className="columns">
                <div className="column is-6 is-12-mobile">
                  <div className="imageHolder">
                    <img
                      src={
                        !!firstColumn.image.src.childImageSharp
                          ? firstColumn.image.src.childImageSharp.fluid.src
                          : firstColumn.image.src
                      }
                      alt={firstColumn.image.alt}
                    />
                  </div>
                  <h3>
                    <a
                      href={firstColumn.link.url}
                      className="has-text-dark hover-text-pink"
                    >
                      {firstColumn.link.title}
                    </a>
                  </h3>
                  <p className="body-small">{firstColumn.description}</p>
                </div>
                <div className="column is-6 is-12-mobile">
                  <div className="imageHolder">
                    <img
                      src={
                        !!secondColumn.image.src.childImageSharp
                          ? secondColumn.image.src.childImageSharp.fluid.src
                          : secondColumn.image.src
                      }
                      alt={secondColumn.image.alt}
                    />
                  </div>
                  <h3>
                    <a
                      href={secondColumn.link.url}
                      className="has-text-dark hover-text-pink"
                    >
                      {secondColumn.link.title}
                    </a>
                  </h3>
                  <p className="body-small">{secondColumn.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

AboutUsTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  firstColumn: PropTypes.shape({
    link: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    description: PropTypes.string,
    image: PropTypes.shape({
      src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      alt: PropTypes.string,
    }),
  }),
  secondColumn: PropTypes.shape({
    link: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    description: PropTypes.string,
    image: PropTypes.shape({
      src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      alt: PropTypes.string,
    }),
  }),
}

const AboutUsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <AboutUsTemplate
        title={frontmatter.title}
        description={frontmatter.description}
        firstColumn={frontmatter.firstColumn}
        secondColumn={frontmatter.secondColumn}
      />
    </Layout>
  )
}

AboutUsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default AboutUsPage

export const pageQuery = graphql`
  query AboutUsTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about-us-page" } }) {
      frontmatter {
        title
        description
        firstColumn {
          image {
            src {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
          link {
            url
            title
          }
          description
        }
        secondColumn {
          image {
            src {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            alt
          }
          link {
            url
            title
          }
          description
        }
      }
    }
  }
`
